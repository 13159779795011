<template>
  <div>
    <div class="header_conner">
      <el-header class="header-top">
        <div>
          <el-row>
            <el-col :span="5">
              <h1>
                <router-link  to="/">
                  <img alt="安全，万方安全,信息安全，网络安全，安全服务，信息安全设备，网络信息安全，信息安全服务，等级保护，风险评估，代码审计，安全运维" class="logo" src="@/assets/images/logo.png">
                </router-link>
              </h1>
            </el-col>
            <el-col :span="9">
              <el-tabs class="pane_items" v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane
                    v-for="item in searchList"
                    :key="item.id"
                    :label="item.name"
                    :name="item.name"
                >
                  <el-input
                      prefix-icon="el-icon-search"
                      v-model="searchInfo"
                      :placeholder="'请输入'+item.name"
                      class="input-with-select"
                  >
                    <el-button slot="append" type="primary">搜索</el-button>
                  </el-input>
                </el-tab-pane>
              </el-tabs>
              <div class="color999 f14 mt5">
                <router-link target="" to="/">云安全</router-link>
                <el-divider  direction="vertical"></el-divider>
                <router-link target="_blank" to="/details">等保二级</router-link>
                <el-divider direction="vertical"></el-divider>
                <router-link target="_blank" to="/details3">等保三级</router-link>
                <el-divider direction="vertical"></el-divider>
                <router-link target="_blank" to="/dengbaozhenggai">等保整改</router-link>
                <el-divider direction="vertical"></el-divider>
                <router-link target="" to="/">风险评估</router-link>
                <el-divider direction="vertical"></el-divider>
                <router-link  to="/">安全产品</router-link>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="menuList">
                <router-link class="el-icon-shopping-cart-full"  to="/">购物车</router-link>
                <!-- <a href="/">文档</a>
                 <a href="/">备案</a> -->
                <router-link  to="/">控制台</router-link>
                <router-link  to="/login">登录</router-link>
                <el-button class="register" type="primary">
                  <router-link  style="color:#fff;margin-right:0;" to="/register">免费注册</router-link>
                </el-button>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <ul class="header_bottom" id="mymenu">
              <li><router-link  to="/">首页</router-link></li>
              <li v-show="sidevi" style="width:78px;" @mouseenter="sideshow=true" @mouseleave="sideshow=false">
                <a href="javascript:">产品 <i class="el-icon-arrow-down"></i></a>
                <div v-show="sideshow" class="sides"><Side/></div>
              </li>

              <li  style="width:120px;" @mouseenter="desc=true" @mouseleave="desc=false">
                <a href="javascript:">公司简介<i class="el-icon-arrow-down"></i></a>
                <div v-show="desc" class="desc_box">
                  <router-link  to="/introduction?id=0">企业文化</router-link>
                  <router-link  to="/introduction?id=1">发展历程</router-link>
                  <router-link  to="/introduction?id=2">专业认证</router-link>
                  <router-link  to="/introduction?id=3">万方生态</router-link>
                  <router-link  to="/introduction?id=4">主营业务</router-link>
                  <router-link  to="/introduction?id=5">主要客户</router-link>
                  <router-link  to="/introduction?id=6">联系方式</router-link>

                </div>
              </li>


              <li><router-link  to="/case">案例介绍</router-link></li>
              <li><router-link  to="/scene">业务应用场景</router-link></li>
              <li><a href="http://www.wfnetworks.cn/wonfone/" target="_blank">万方科技</a></li>
              <li><a href="http://www.wfnetworks.cn/" target="_blank">支持与服务</a></li>
              <li><router-link  to="/articleList">热点资讯</router-link></li>

<!--              <li  v-for="(menu,key) in menus" v-show="menu.menParentId==0" :key=key  @mouseenter="abc=true;" @mouseleave="abc=false">-->
<!--&lt;!&ndash;                <router-link v-show="menu.childcount==0" :to="menu.menUrl" >{{menu.menName}}</router-link>&ndash;&gt;-->
<!--                &lt;!&ndash;<div v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==menu.menId" class="desc_box">-->
<!--                  <router-link :to="childmenu.menUrl">{{childmenu.menName}}</router-link>-->
<!--                </div>&ndash;&gt;-->
<!--                &lt;!&ndash;<a v-show="menu.childcount!==0" href="javascript:">{{menu.menName}}<i class="el-icon-arrow-down"></i></a>/&ndash;&gt;-->
<!--                <a v-show="menu.menId!==1" href="javascript:">{{menu.menName}}<i class="el-icon-arrow-down"></i></a>-->
<!--                &lt;!&ndash;<div v-show="desc" class="desc_box">-->
<!--                  <router-link v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==menu.menId"  :to="childmenu.menUrl">{{childmenu.menName}}</router-link>-->
<!--                </div>&ndash;&gt;-->
<!--                <div v-show="abc" class="sides"><Side /></div>-->
<!--&lt;!&ndash;                <div v-show="menu.menId=='2'&&abc" class="sides"><:{{ menu.menTable } /></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div v-for="(menu1,key) in {{menu.menTable}}" v-show="abc==menu.menTable" class="desc_box">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                  <router-link  to="/introduction?id=0">企业文化</router-link>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                  <a v-show="menu.menId!==1" href="javascript:">{{menu.menName}}<i class="el-icon-arrow-down"></i></a>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div v-show="desc" class="desc_box">&ndash;&gt;-->
<!--&lt;!&ndash;                    <router-link to="/introduction?id=0">具体点的</router-link>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--              </li>-->
              <li  v-for="(menu,key) in menus" v-show="menu.menParentId==0" :key=key  @mouseenter="sideshow=true" @mouseleave="sideshow=false">
                <router-link v-show="menu.childcount==0" :to="menu.menUrl">{{menu.menName}}</router-link>
                <!--<div v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==menu.menId" class="desc_box">
                  <router-link :to="childmenu.menUrl">{{childmenu.menName}}</router-link>
                </div>-->
                <a v-show="menu.childcount!==0" href="javascript:">{{menu.menName}}<i class="el-icon-arrow-down"></i></a>
                <!--<div v-show="desc" class="desc_box">
                  <router-link v-for="(childmenu,key) in childmenus" :key=key v-show="childmenu.menParentId==menu.menId"  :to="childmenu.menUrl">{{childmenu.menName}}</router-link>
                </div>-->
                <div v-show="sideshow" class="sides"><Side/></div>
              </li>
            </ul>
            <div class="phones">
              <img style="height:31px;" src="@/assets/images/phone.png">
              <span >188 188 60088(微信同号)188 188 63388(微信同号)</span>
             
            </div>
          </el-row>
        </div>
      </el-header>

    </div>
    <router-view></router-view>
    <el-backtop></el-backtop>
    <Footer/>
  </div>
</template>

<script type="text/javascript">
import Footer from "@/components/Footer.vue"
import Side from "@/components/Side.vue"
/*import * as HttpUtil from "@/utils/request";
import * as apiMap from "@/constants/api";
import {Message} from "element-ui";*/

//import { Vue } from "./vue";

//Vue.prototype.$axios = axios


export default{
  components:{
    Footer,
    Side
  },
  el:"#mymenu",
  data(){
    return{
      searchInfo: "",
      activeName: "全部",
      sidevi:true,
      sideshow:false,
      desc:false,
      menus:[],
      // childmenus:[],
      // childofmenus:[],
      searchList: [
        {
          id: 0,
          name: "全部"
        },
        {
          id: 1,
          name: "等保"
        },
        {
          id: 2,
          name: "风评"
        },
        {
          id: 3,
          name: "安服"
        }
      ],
      // ProductTypesAll:[],
      // SoluTypesAll:[],
      // InfoTypesAll:[],
      // HotTypesAll:[],
      // EcospheresAll:[],
      // WfTypesAll:[]
      m_product_type:[],
      m_solu_type:[],
      m_info_type:[],
      m_hot_type:[],
      m_ecosphere:[],
      WfTypesAll:[]
      // DesTypes:[],
    }
  },
  mounted(){
    if(this.$route.path=='/'){
      this.sidevi=false
    }else{
      this.sidevi=true
    }

  },
  methods:{
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    mouseenter(num) {
      this.current1 = num;
    },
    mouseleave() {
      this.current1 = 1000;
    },

    page(currentPage){
      // return new Promise((resolve,reject)=>{
      //   HttpUtil.get(apiMap.GET_MENU_LIST,requestData,{isLoading:false}).then((res)=>{
      //     if(res.error==0){
      //       resolve(res);
      //       // Message({
      //       //      	type:'success',
      //       //      	message:res.content
      //       //      });
      //     }else{
      //       Message({
      //         type:'error',
      //         message:res.content
      //       });
      //     }
      //   })
      // });
      const _this = this
      // _this.$axios.get("/menus?currentPage=" + currentPage).then(res => {
      let modelid="2";
      _this.$axios.get("/menusWithModelId?modelid=" + modelid).then(res => {
        var data = res.data.data
        _this.menus = data
      })
      _this.$axios.get("/ProductTypesAll").then(res => {
        var data = res.data.data
        _this.m_product_type = data

        _this.$message({
          message: _this.m_product_type,
          type: 'success',
        });



      })
    },
  },
  watch:{
    $route(to,from){
      if(to.path=='/'){
        this.sidevi=false
      }else{
        this.sidevi=true
      }
    }
  },
  created() {
    this.page(1)
  }

};
</script>


<style lang="less"  scoped >
.header_conner{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  background:#fff;

  .header-top{
    width:1200px;
    min-width: 1200px;
    margin:0 auto;
    margin-bottom:0;
    height: auto !important;
  }
  .el-header{
    height: auto;
  }
  .logo{
    width:200px;
    margin-left:20px;
    margin-top:3px;
  }
  .el-header{
    padding: 0;
    background-color: #0e82cb;
  }
  .el-header {
    background-color: white;
    padding-top:20px;
  }
  .el-tabs__item.is-active {
    background-color: #0e82cb;
    color: white;
    border-bottom:1px solid #0e82cb;
  }
  .el-tabs__item.is-active:hover {
    color: white;
  }
  .el-tabs__item:hover {
    color: black;
  }
  .el-tabs__item:nth-child(2){
    padding-left:0;
  }
  .el-tabs__item{
    font-size:12px;
    width: auto;
    padding: 0 8px;
    line-height: 24px;
    text-align:center;
    height:24px;
    border-left:0;
  }
  .el-button--primary {
    color: #FFF;
    background-color: #0e82cb;
    border-color: #0e82cb;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #0e82cb;
    border-color: #0e82cb;
    color: #FFF;
  }
  .el-input-group__append button.el-button{
    color:#FFF;
    background-color: #0e82cb;
    border-color: #0e82cb;
  }
  .register{
    padding:10px 20px;
  }
  .menuList {
    margin-top: 22px;
    float: right;
    display: flex;
    align-items: center;
  }
  .menuList a {
    margin-right: 15px;
    font-size:14px;
    text-decoration: none;
    color: black;
  }
  .menuList a:hover {
    color:#0e82cb;
  }
  .el-tabs__header {
    margin: 0px;
  }
  .el-tabs--card>.el-tabs__header {
    border:0;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border:0;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
    border-bottom: 1px solid #0e82cb;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-left:0;
    height: 28px;
    line-height: 28px;
  }
  .el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 10px;
  }
  /deep/.el-input__inner{
    height:32px;
    line-height: 32px;
    border:2px solid #0e82cb;
  }
  .el-input__inner:hover,.el-input__inner:focus{
    border:2px solid #0e82cb;
  }
  .mt5 a{
    font-size:12px;
    display:inline-block;
    text-decoration: none;
    color:#999;
    margin-top:3px;
  }
  .mt5 a:hover{
    color:#0e82cb;
  }
  .header_bottom{
    padding:0;
    margin-top:20px;
    margin-bottom:15px;
    display: flex;
  }
  .header_bottom>li{
    /*position: relative;*/
  }
  .header_bottom a{
    padding: 0 16px;
    color: #181818;
    font-size: 14px;
    line-height: 35px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    overflow: hidden;
  }
  .header_bottom a:hover{
    color:#0e82cb;
  }
  .el-input__icon{
    line-height: 33px
  }
  .phones{
    float:right;
    position: absolute;
    right:10px;
    bottom:10px;
    font-size:16px;
    line-height: 30px;
  }
  .phones img{
    margin-right:5px;
    margin-left:10px;
  }
  .sides{
    padding-top:5px;
    position: absolute;
    top:28px;
    left:0px;
    z-index:8;
    width:240px;
  }
  .pane_items /deep/.el-tabs__header{
    margin:0;
  }
  .pane_items /deep/.el-tabs__item{
    height:30px;
    line-height: 30px;
  }
  .desc_box{
    position:absolute;
    background:#fff;
    padding-top:15px;
    z-index:5;
  }
  .desc_box a{
    width:65px;
    text-align:center;
    font-size:14px;
    display: block;
    color:#181818;
    border-top:1px solid #ddd;
  }


}


</style>
